var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "search-view" },
    [
      _c(
        "filter-container",
        [
          _c("div", { staticClass: "handle-actions" }, [
            _c("span", { staticClass: "active" }, [_vm._v("Search")]),
            _vm._v(" "),
            _c("span", { on: { click: _vm.handleChartsAction } }, [
              _vm._v("Charts"),
            ]),
          ]),
          _vm._v(" "),
          _c("class-filter", {
            attrs: { single: true },
            model: {
              value: _vm.filters.classes,
              callback: function ($$v) {
                _vm.$set(_vm.filters, "classes", $$v)
              },
              expression: "filters.classes",
            },
          }),
          _vm._v(" "),
          _c("genre-filter", {
            model: {
              value: _vm.filters.genres,
              callback: function ($$v) {
                _vm.$set(_vm.filters, "genres", $$v)
              },
              expression: "filters.genres",
            },
          }),
          _vm._v(" "),
          _c("key-filter", {
            attrs: { single: false },
            model: {
              value: _vm.filters.keys,
              callback: function ($$v) {
                _vm.$set(_vm.filters, "keys", $$v)
              },
              expression: "filters.keys",
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "bpm-filter" },
            [
              _c("h2", [_vm._v("BPM")]),
              _vm._v(" "),
              _c("p", { staticClass: "bpm-values" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.filters.bpm[0] + " > " + _vm.filters.bpm[1]) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c("vue-slider", {
                attrs: { min: 0, max: 200, "enable-cross": false, lazy: true },
                on: {
                  "~drag-end": function ($event) {
                    return _vm.doSearch.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.filters.bpm,
                  callback: function ($$v) {
                    _vm.$set(_vm.filters, "bpm", $$v)
                  },
                  expression: "filters.bpm",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "search-results", staticStyle: { width: "100%" } },
        [
          _c("div", { staticClass: "search-results-count" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                staticStyle: { "text-align": "center", margin: "30% 0" },
              },
              [_vm._v("\n        Searching deep within phase...\n      ")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.loading,
                    expression: "!loading",
                  },
                ],
              },
              [
                _vm.$store.state.search.searchTerm.length
                  ? _c("span", [
                      _vm._v(
                        "for '" +
                          _vm._s(_vm.$store.state.search.searchTerm) +
                          "'"
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.filters.genres.length ||
                _vm.filters.classes.length ||
                _vm.filters.keys.length
                  ? _c("span", [_vm._v("in")])
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(_vm.filters.genres, function (genre, i) {
                  return _c("span", { key: i }, [
                    _vm._v("'" + _vm._s(genre.name) + "'"),
                    _vm.filters.genres[i + 1]
                      ? _c("span", [_vm._v(",\n          ")])
                      : _vm._e(),
                  ])
                }),
                _vm._v(" "),
                _vm._l(_vm.filters.classes, function (type, i) {
                  return _c("span", { key: i }, [
                    _vm._v("'" + _vm._s(type.name) + "'"),
                    _vm.filters.classes[i + 1]
                      ? _c("span", [_vm._v(",\n          ")])
                      : _vm._e(),
                  ])
                }),
                _vm._v(" "),
                _vm._l(_vm.filters.keys, function (key, i) {
                  return _c(
                    "span",
                    {
                      key: i,
                      domProps: { innerHTML: _vm._s("'" + key.name + "'") },
                    },
                    [
                      _vm.filters.keys[i + 1]
                        ? _c("span", [_vm._v(", ")])
                        : _vm._e(),
                    ]
                  )
                }),
              ],
              2
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "search-results-grid" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.loading,
                    expression: "!loading",
                  },
                ],
                key: _vm.index,
                staticClass: "search-result",
              },
              [
                _c("search-result", {
                  attrs: {
                    users: _vm.users,
                    releases: _vm.releases,
                    tracks: _vm.tracks,
                    counts: _vm.counts,
                    pages: _vm.pages,
                    loadingUserMore: _vm.loadingUserMore,
                    loadingReleaseMore: _vm.loadingReleaseMore,
                    loadingTrackMore: _vm.loadingTrackMore,
                    loadingTrack: _vm.loadingTrack,
                    resultCounts: _vm.resultCounts,
                  },
                  on: {
                    handleUserLoad: _vm.handleUserLoad,
                    handleReleaseLoad: _vm.handleReleaseLoad,
                    handleTrackLoad: _vm.handleTrackLoad,
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }