var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "search-filter-group" }, [
    _c("h2", [_vm._v("Key/Note")]),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "filter-buttons" },
      _vm._l(_vm.$store.state.app.keys, function (key, index) {
        return _c(
          "div",
          { key: index, staticClass: "button-container" },
          [
            _c(
              "ph-button",
              {
                attrs: {
                  size: "medium",
                  color: "filter",
                  width: "100%",
                  type: "search-filter",
                  active: _vm.isActive(key),
                },
                nativeOn: {
                  click: function ($event) {
                    _vm.single
                      ? _vm.setSingleFilter(key)
                      : _vm.toggleFilter(key)
                  },
                },
              },
              [_vm._v(_vm._s(_vm._f("clean")(key.name)))]
            ),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }