var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "search-filter-group" },
    [
      _vm._t("default", function () {
        return [_c("h2", [_vm._v(_vm._s(_vm.title))])]
      }),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "filter-buttons" },
        _vm._l(_vm.$store.state.app.genres, function (genre) {
          return _c(
            "div",
            { staticClass: "button-container" },
            [
              _c(
                "ph-button",
                {
                  attrs: {
                    size: "medium",
                    color: "filter",
                    width: "100%",
                    type: "search-filter",
                    active: _vm.isActiveGenre(genre),
                  },
                  nativeOn: {
                    click: function ($event) {
                      _vm.single
                        ? _vm.setSingleFilter(genre)
                        : _vm.toggleFilter(genre)
                    },
                  },
                },
                [_vm._v(_vm._s(genre.name))]
              ),
            ],
            1
          )
        }),
        0
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }