<template>
  <div class="search-filter-group">
    <slot>
      <h2>{{ title }}</h2>
    </slot>
    <br />
    <div class="filter-buttons">
      <div class="button-container" v-for="(sort, i) in $store.state.app.sortFilter">
        <ph-button
          size="medium"
          color="filter"
          width="100%"
          type="search-filter"
          :active="isActiveSort(sort)"
          @click.native="single ? setSingleFilter(sort) : toggleFilter(sort)"
        >{{ sort }}</ph-button>
      </div>
    </div>
    
  </div>
</template>

<script>
import FilterMethods from "./filter-methods";
import { mapState } from "vuex";

export default {
  data() {
    return {
      filters: this.value,
      options: ['Most popular', 'Most purchased', 'Most recent'],
    };
  },
  props: {
    value: {
      type: Array,
      default: [],
    },
    single: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Sort",
    },
  },
  computed: mapState(["app"]),
  mixins: [FilterMethods],
};
</script>

<style scoped>
h3 {
  font-size: 17px;
  margin-bottom: 5px;
}
</style>

<style lang="scss">
.range-slider-fill {
  background-color: #9eefe1;
}
</style>